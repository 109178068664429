<template>
	<a v-if="type === 'external'" :href="link" class="transition-all ease-linear" rel="nofollow" target="_blank" :title="label">
		<slot name="prefix" />
		<span v-text="label"></span>	
		<slot name="suffix" />
	</a>
	<router-link v-else-if="type === 'link'" :to="link" class="transition-all ease-linear" rel="follow" :title="label">
		<slot name="prefix" />
		<span v-text="label"></span>	
		<slot name="suffix" />
	</router-link>
	<p v-else-if="type === 'anchor'" @click="scrollTo(link)" class="cursor-pointer transition-all ease-in-out relative">
		<slot name="prefix" />
		<span v-text="label"></span>	
		<slot name="suffix" />
	</p>
</template>
<script>
export default {
	name: 'ALink',
	props: {
		link: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'link'
		},
		label: {
			type: String,
			default: ''
		},
	},
	methods: {
		scrollTo(id) {
			const el = document.getElementById(id)
			const top = ((el.getBoundingClientRect().top) + window.scrollY) 
			window.scrollTo({
				top, 
				left: 0,
				behavior: 'smooth',
			})
		}
	},
}
</script>