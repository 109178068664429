import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import App from './App.vue'

import './styles/app.css'
import router from "@/routes";

const head = createHead()

createApp(App)
.use(head)
.use(router)
.mount('#app')
