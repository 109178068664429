<template>
	<div class="grid bg-white border-[#DBE6F2] border rounded-lg p-6 max-w-[390px]">
		<div class="grid grid-cols-[48px,1fr] items-center mb-6 gap-4">
			<img :src="card.picture" alt="dummy" class="w-12 h-12 rounded-full overflow-hidden object-cover" />
			<div class="grid">
				<h3 class="text-black font-semibold text-md leading-2" v-text="card.title"></h3>
				<div class="flex justify-start md:items-center md:gap-4 md:flex-row flex-col items-start gap-0">
					<ul class="flex items-center md:my-0 my-px">
						<li v-for="(n, index) in card.stars" :key="index">
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<rect width="16" height="16" fill="url(#star)" />
								<defs>
									<pattern id="star" patternContentUnits="objectBoundingBox" width="1" height="1">
										<use xlink:href="#image0_204_41" transform="scale(0.025641 0.0263158)" />
									</pattern>
									<image id="image0_204_41" width="42" height="38" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmBAMAAABALxQTAAAAFVBMVEVMaXH4twP4twP4twP4twP4twP4twP7w8S/AAAAB3RSTlMAFv5uPpvQloUsTQAAAMFJREFUeAGE0TEOgzAMQFEXoDNiYC6/wFxxAsTADDkB5f6HqNRENXUi8TYiRfnY8lNXkjBOkuBWSeAhsYJOYiW9xO4MEqshkTbCSyIH7GLdgFasHHgmwkikZQD6OROZRG4Hxju8o/TNhbNhCqkOxaZDVKdxNnq/EjUS/A2o0PuXpyVeb9bjDWY9QSWXDQfBbtbjtWY9bM4sqfx+5yYt8wNcAFEzrGGkk5668KsFrKewPtQ3aFqh8WOnYZ+lIBQkgykAWk8rlAqcHfQAAAAASUVORK5CYII=" />
								</defs>
							</svg>
						</li>
					</ul>
					<span class="text-[#6A6A6A] text-sm leading-3">via Google Reviews</span>
				</div>
			</div>
		</div>
		<p class="leading-3 text-[#6A6A6A] text-base" v-html="card.text"></p>
	</div>
</template>
<script>
export default {
	name: 'CardComponent',
	props: {
		card: {
			type: Object,
			required: true
		}
	}
}
</script>
