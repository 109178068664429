import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
	path: '/',
	name: 'Home',
	component: () => import('../App.vue')
}]

 const router = createRouter({
	history: createWebHistory(),
	routes,
	// eslint-disable-next-line no-unused-vars
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	},
})

export default router