<template>
	<section class="bg-gradient-to-b from-[#fff_55%] to-[#FCB813_55%] pb-28 overflow-x-hidden">
		<div class="content-area mb-[4.5rem] lg:px-0 px-6">
			<div class="grid">
				<h4 class="text-selective-yellow lg:text-left text-center font-semibold leading-2 uppercase mb-3">nossos diferenciais</h4>
				<div class="lg:flex grid items-center justify-between">
					<h3 class="font-poppins font-semibold text-lg leading-[2rem] md:text-start text-center">O que nossos clientes dizem </h3>
					<div class="lg:flex grid items-center md:justify-start justify-center">
						<ul class="flex items-center -space-x-1.5 hover:space-x-1 lg:my-0 my-6 md:justify-start justify-center lg:mr-4 mr-0">
							<li class="h-10 w-10 rounded-full overflow-hidden ring-2 ring-white transition-all ease-linear"><img width="40" height="40" src="@/assets/user-01.webp" alt="User 1" /></li>
							<li class="h-10 w-10 rounded-full overflow-hidden ring-2 ring-white transition-all ease-linear"><img width="40" height="40" src="@/assets/user-02.webp" alt="User 2" /></li>
							<li class="h-10 w-10 rounded-full overflow-hidden ring-2 ring-white transition-all ease-linear"><img width="40" height="40" src="@/assets/user-03.webp" alt="User 3" /></li>
							<li class="h-10 w-10 rounded-full overflow-hidden ring-2 ring-white transition-all ease-linear"><img width="40" height="40" src="@/assets/user-04.webp" alt="User 4" /></li>
						</ul>
						<span class="text-selective-yellow ml-0 md:ml-06 font-semibold">+ de 150 mil CLIENTES</span>
						<div class="hidden lg:flex items-center gap-2 ml-8">
							<button id="prev" class="w-10 h-10 grid place-content-center border-[#DBE6F2] border rounded-full cursor-pointer">
								<svg class="rotate-180" width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.16202 12L0 10.7351L4.35573 6L0 1.26495L1.16532 0L5.51775 4.73505C5.82654 5.07057 6 5.52557 6 6C6 6.47442 5.82654 6.92943 5.51775 7.26495L1.16202 12Z" fill="#DBE6F2"/>
								</svg>
							</button>
							<button id="next" class="w-10 h-10 grid place-content-center border-black border rounded-full cursor-pointer">
								<svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.16202 12L0 10.7351L4.35573 6L0 1.26495L1.16532 0L5.51775 4.73505C5.82654 5.07057 6 5.52557 6 6C6 6.47442 5.82654 6.92943 5.51775 7.26495L1.16202 12Z" fill="black"/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
			<ul id="testimonials" class="lg:max-w-[1342px] w-screen translate-y-1/4">
				<li v-for="(card, index) in cards" :key="index">
					<CardComponent :card="card" class="mr-8" />
				</li>
			</ul>
		</div>
	</section>
</template>
<script>
import CardComponent from '@/components/CardComponent.vue'
export default {
	name: 'TestimonialsSection',
	components: {
		CardComponent
	},
	data() {
		return {
			cards: [{
				picture: require('@/assets/testimonial-01.png'),
				title: 'Izabel Mattos',
				stars: 5,
				text: 'Mais uma vez agradeço ao atendimento <br class="hidden lg:inline-block" /> super VIP da atendente YASMIM! Já sou <br class="hidden lg:inline-block" /> cliente há algum tempo e a recomendo para <br class="hidden lg:inline-block" /> um atendimento tranquilo, sem dor de <br class="hidden lg:inline-block" /> cabeça e além disso, com muita simpatia e <br class="hidden lg:inline-block" /> dedicação!',
			},{
				picture: require('@/assets/testimonial-02.png'),
				title: 'Susete Aparecida',
				stars: 5,
				text: 'O consultor Bruno Espíndola é maravilhoso, <br class="hidden lg:inline-block" /> super competente, atencioso, dedicado, <br class="hidden lg:inline-block" /> nota 1000. Um profissional altamente <br class="hidden lg:inline-block" /> qualificado. Estou muito feliz com o <br class="hidden lg:inline-block" /> resultado do atendimento que ele prestou a <br class="hidden lg:inline-block" /> mim. Muito Obrigado.'
			},{
				picture: require('@/assets/testimonial-0.png'),
				title: 'Cleverson',
				stars: 5,
				text: 'Profissionais capacitados para o trabalho <br class="hidden lg:inline-block" /> não tem preço tem valor, por isso <br class="hidden lg:inline-block" /> parabenizo Ana Souza que me prestou um <br class="hidden lg:inline-block" /> excelente atendimento, que tenhamos mais <br class="hidden lg:inline-block" /> pessoas com essa desenvoltura para o <br class="hidden lg:inline-block" /> serviço, mais uma vez só agradecer.'
			}, {
				picture: require('@/assets/testimonial-0.png'),
				title: 'Andressa Martins',
				stars: 5,
				text: 'Fui atendida pela Gabriela Savedra,<br class="hidden lg:inline-block" /> ainda estava um pouco insegura com a <br class="hidden lg:inline-block" /> antecipação do FGTS, ela prestou todo <br class="hidden lg:inline-block" /> o atendimento me passou total <br class="hidden lg:inline-block" /> confiança, o dinheiro caiu na minha <br class="hidden lg:inline-block" /> conta em menos de 1 hora. Obrigada <br class="hidden lg:inline-block" /> Gabriela está de parabéns.'
			}]
		}
	},
	mounted() {
		// eslint-disable-next-line no-undef
		$("#testimonials").slick({
			slidesToShow: 3.18,
			slidesToScroll: 1,
			centerMode: false,
			infinite: false,
			dots: false,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 2000,
			// eslint-disable-next-line no-undef
			prevArrow: $("#prev"),
			// eslint-disable-next-line no-undef
			nextArrow: $("#next"),
			responsive: [{
					breakpoint: 998,
					settings: {
						slidesToShow: 2.33,
						slidesToScroll: 1,
						infinite: true,
						centerMode: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1.33,
						slidesToScroll: 1,
					}
				}
			]
		})
	},
}
</script>