<template>
	<THeader :originTag="originTag" />
	<main>
		<Banner id="home" :originTag="originTag" />
		<QuotationComponent :originTag="originTag" />
		<ValueSection id="vantagens" :originTag="originTag" />
		<HistorySection id="como-obter" :originTag="originTag" />
		<FaqSection id="faq" :originTag="originTag" />
		<TestimonialsSection id="depoimentos" />
		<QuotationComponent :dark="false" :originTag="originTag" />
	</main>
	<TFooter :originTag="originTag" />
</template>
<script>
import { useHead } from "@vueuse/head"

import THeader from "@/components/Header.vue"
import TFooter from "@/components/Footer.vue"
import Banner from "@/components/Banner.vue"
import QuotationComponent from "@/components/Quotation.vue"

import ValueSection from "@/sections/ValueSection.vue"
import HistorySection from "@/sections/HistorySection.vue"
import FaqSection from "@/sections/FaqSection.vue"
import TestimonialsSection from "@/sections/TestimonialsSection.vue"

export default {
	name: "App",
	setup() {
		useHead({
			title: "VIP Financeira FGTS - Promotora de Crédito",
			meta: [
				{
					name: `description`,
					content: `A VIP Promotora de Crédito está no mercado há +15 anos e é uma das maiores promotoras de crédito do país. Essa é uma garantia aos nossos clientes de que todo o processo terá credibilidade, segurança, qualidade e excelência.`
				}
			]
		})
	},
	components: {
		THeader,
		TFooter,
		Banner,
		QuotationComponent,
		ValueSection,
		HistorySection,
		FaqSection,
		TestimonialsSection
	},
  data() {
    return {
      originTag: ''
    }
  },
  methods: {
    getOriginTag() {
        const urlSearchParams = new URLSearchParams(window.location.search)
        return urlSearchParams.get('tag') ?? null
    }
  },
	mounted() {
    this.originTag = this.getOriginTag()
		// eslint-disable-next-line no-undef
		var cc = initCookieConsent()

		cc.run({
			current_lang: "pt",
			autoclear_cookies: true, // default: false
			theme_css: "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.0/dist/cookieconsent.css",
			page_scripts: true, // default: false
			languages: {
				pt: {
					consent_modal: {
						title: "",
						description: 'Coletamos informações através de cookies, de acordo com nossa "Política de Privacidade" e, ao continuar navegando neste site, você declara estar ciente dessas condições.',
						primary_btn: {
							text: "Eu Concordo",
							role: "accept_all" // 'accept_selected' or 'accept_all'
						},
						secondary_btn: {
							text: '<a href="https://saquefgts.vipfinanceira.com.br/politica.html" class="cc-link" target="_blank">Política de Cookies</a>',
							role: "accept_necessary" // 'settings' or 'accept_necessary'
						}
					},

					settings_modal: {
						title: "Preferencia de Cookie",
						save_settings_btn: "Salvar Configurações",
						accept_all_btn: "Aceitar Todos",
						reject_all_btn: "Rejeitar Todos",
						close_btn_label: "Fechar",
						cookie_table_headers: [
							{
								col1: "Nome"
							},{
								col2: "Dominio"
							},{
								col3: "Expiração"
							},{
								col4: "Descrição"
							}
						],

						blocks: [
							{
								title: "Uso de Cookies 📢",
								description: 'Nós usamos cookies para garantir as funcionalidades básicas do site e melhorar sua experiência online. Você pode escolher cada categoria para ativar/desativar sempre que quiser. Para obter mais detalhes sobre cookies e outros dados confidenciais, leia a íntegra <a href="https://saquefgts.vipfinanceira.com.br/politica.html" class="cc-link" target="_blank">política de privacidade</a>.'
							},{
								title: "Cookies estritamente necessários",
								description: "Esses cookies são essenciais para o bom funcionamento do site. Sem esses cookies, o site não funcionaria corretamente",
								toggle: {
									value: "necessário",
									enabled: true,
									readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
								}
							},{
								title: "Cookies de desempenho e análise",
								description: "Esses cookies permitem que o site se lembre das escolhas que você fez no passado",
								toggle: {
									value: "analytics", // your cookie category
									enabled: false,
									readonly: false
								},

								cookie_table: [
									{
										col1: "^_ga", // match all cookies starting with "_ga"
										col2: "google.com",
										col3: "1 ano",
										col4: "métrica",
										is_regex: true
									}
								]
							},{
								title: "Cookies de publicidade e segmentação",
								description: "Esses cookies coletam informações sobre como você usa o site, quais páginas você visitou e quais links você clicou. Todos os dados são anonimizados e não podem ser usados ​​para identificá-lo.",
								toggle: {
									value: "targeting",
									enabled: false,
									readonly: false
								}
							},{
								title: "Mais informações",
								description: 'Para quaisquer dúvidas em relação à nossa política de cookies e suas escolhas, por favor <a class="cc-link" href="mailto:sac@vipfinanceira.com.br">contate-nos</a>.'
							}
						]
					}
				}
			}
		})
	}
}
</script>
<style>
#cm {
    left: 20px;
}

#c-bns button:first-child,
#s-bns button:first-child {
    background-color: #000 !important;
}

#c-ttl {
    color: #FCB813 !important;
}

#s-rall-bn,
#s-sv-bn,
#c-s-bn {
    color: #000 !important;
}

.cc-link,
.b-tl .exp {
    color: #FCB813 !important;
    border-color: #FCB813 !important;
}

.cc_div .b-tg .c-tgl:checked~.c-tg {
    background: #FCB813 !important;
}


.cc_div .b-tg .c-tgl~.c-tg.c-ro {
    opacity: .6 !important;
}
.cc_div .cc-link {
	white-space: nowrap !important;
}
</style>